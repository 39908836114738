import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import "../components/css/publishing-store-success.css";
import "../components/css/policy.css";

export default function ServiceTerms() {
  return (
    <div className="container-main" id="page">
      <Header />
      <main className="content-main">
        <div className="row">
          <div className="col">
            <div className="policies-main px-3 py-5 mb-5">
              <h3>Thank you for using 99Gens!</h3>
              <h3>SERVICE TERMS</h3>
              <p>
                Your use of the Services is governed by these Service Terms. Any
                capitalized terms not defined here will have the meanings in the
                Agreement you have with us, such as the Terms of Use, Enterprise
                Agreement, or any other relevant agreement governing your use of the
                Services. In the event of any conflict between the Service Terms and
                your Agreement, these Service Terms will take precedence. It is
                important to note that for the purposes of these Terms, "Content"
                encompasses "Customer Content."
              </p>
              <h3>1. AI Generated Designs</h3>
              <p>
                All AI Generated Designs happen via third-party APIs (e.g. Stable
                Diffusion, Dall-E). 99GENS LLC makes no representations or warranties
                for Third-Party Services, including any warranty that Third-Party
                Services will be generally available, uninterrupted or error-free, or
                that Content will be secure or not lost or damaged. Except to the
                extent prohibited by law, 99GENS LLC expressly disclaims all
                warranties for Third-Party Services, including any implied warranties
                of merchantability, satisfactory quality, fitness for a particular
                purpose, non-infringement, or quiet enjoyment, and any warranties
                arising out of any course of dealing or usage of trade.
              </p>
              <h3>2. Print On Demand (“POD”)</h3>
              <p>
                99GENS LLC makes no representations or warranties for POD’s. Please
                note that all POD services and products are facilitated through
                third-party vendors and partners, and 99GENS LLC does not provide any
                representations or warranties for these third-party services. This
                includes any warranty that POD services will be generally available,
                uninterrupted or error-free, or that Content will be secure or not
                lost or damaged. Except where prohibited by law, 99GENS LLC expressly
                disclaims all warranties for third-party POD services, including any
                implied warranties of merchantability, satisfactory quality, fitness
                for a particular purpose, non-infringement, or quiet enjoyment, and
                any warranties arising out of any course of dealing or usage of trade.
              </p>
              <p>
                In the event that any POD service fails to deliver, 99GENS LLC will
                take reasonable measures to secure a refund from the respective vendor
                or partner, and will pass on any such refund to the affected customer.
                However, 99GENS LLC cannot be held responsible for any losses or
                damages that may result from such POD service failures, and the
                customer acknowledges that their use of third-party services is at
                their own risk. At any time, you may request a list of our connected
                partners and the specific products and services they provide by
                contacting us. We will make reasonable efforts to provide you with the
                requested information in a timely manner, subject to any applicable
                legal or contractual limitations. Requests may be forwarded to
                <a href="mailto:legal@99gens.com"> legal@99gens.com</a>.
              </p>
              <h3>3. Merchification Results</h3>
              <p>
                Please be advised that 99GENS LLC's proprietary algorithm for creating
                on-demand merchandise may, in some cases, rely on third-party
                services. However, 99GENS LLC does not make any warranties or
                representations of uptime or availability for these third-party
                services. It is important to note that third-party services may
                experience downtime or interruptions, and 99GENS LLC cannot be held
                responsible for any such occurrences. The customer acknowledges that
                their use of third-party services is at their own risk.
              </p>
              <h3>4. Search</h3>
              <p>
                Please be advised that 99GENS LLC's search engine may rely on
                third-party services for some of its functionality. However, 99GENS
                LLC does not make any warranties or representations of uptime or
                availability for these third-party services. It is important to note
                that third-party services may experience downtime or interruptions,
                and 99GENS LLC cannot be held responsible for any such occurrences.
                The customer acknowledges that their use of third-party services is at
                their own risk.
              </p>
              <p>
                <a onClick={() => window.scrollTo(0, 0)}>Return to top</a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </div>
  );
};
